.logo {
    display: flex;
    flex: none;
    gap: 12px;
    align-items: center;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    height: 80px;
    text-decoration: none;
    color: var(--color-text-primary);
}
