pre {
    background: #dae4ee42;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #dae4ee;
    padding: 5px;
    box-sizing: border-box;
    color: black;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
}

.link {
    color: var(--color-text-acccent);
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.cell {
    flex: 1;
}

.status {
    flex: none;
}