.opacity {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}

.updating {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #e7edf3;
    opacity: 0.9;
}

.loader {
    color: #fff;
}

.inner_wrapper {
    height: 100%;
}

.through {
    text-decoration: line-through;
}

.cancel {
    margin-left: 8px;
    text-decoration: underline !important;
    font-size: 12px !important;
}
