.double_modal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.double_modal > div:first-child {
    height: 100%;
    background-color: var(--color-bg-primary);
    box-sizing: border-box;
    display: flex;
    min-width: 320px;
    flex-direction: column;
    flex: 2;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
}

.double_modal > div:last-child:not(:only-child) {
    flex: 1;
    min-width: 320px;
    background-color: var(--color-bg-secondary);
    box-sizing: border-box;
    padding: var(--gap-xl);
    overflow: auto;
}

.header_wrapper {
    position: sticky;
    top: 0;
    z-index: 20;
    margin: 0 -32px;
}

.image_skeleton {
    height: 200px;
    border-radius: 8px;
    margin-bottom: 12px;
    width: 100%;
}

.image_skeleton::before {
    background-color: var(--color-graphic-secondary-inverted) !important;
}

.info_skeleton {
    height: 124px;
    border-radius: 8px;
    width: 100%;
}

.info_skeleton::before {
    background-color: var(--color-graphic-secondary-inverted) !important;
}
