.clear {
    right: 20px;
    top: 20px;
    position: absolute !important;
}
.canvas {
    position: relative;
}

.canvas, canvas {
    touch-action: none;
}

.text {
    position: absolute;
    left: 20px;
    top: 26px;
    text-align: center;
}
