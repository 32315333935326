.inner_wrapper {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    max-width: 460px;
}


.head {
    text-align: center;
}