@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800;900&display=swap');

body,
html {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: var(--color-bg-primary); */
}

p {
    margin-top: 0;
    margin-bottom: 8px;
}

.container {
    /* position: absolute; */
    background: var(--color-bg-special-super-light);
    /* overflow: auto;
    width: 100%;
    height: 100%;
    max-height: 100%; */
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper {
   width: 900px;
   overflow: hidden;
   margin: 16px auto;
   background: #fff;
   border-radius: 12px;
   display: flex;
   align-items: center;
   justify-content: center;
   min-height: 100px;
   border: 1px solid var(--color-border-secondary);
}

.logo img {
    width: 168px;
}


@media (max-width: 980px) {
    .wrapper {
        width: 100%;
    }

    .content {
        padding: 80px 12px;
    }
}